<template>
  <div>
    <!-- filter -->
    <b-card
      no-body
      class="mb-1"
    >
      <b-card-header class="py-1">
        <h4 class="card-title font-weight-bolder">
          {{ $t('filters') }}
        </h4>
        <div class="d-flex align-items-center justify-content-end">

          <!-- ANCHOR Button Search -->
          <b-button
            variant="info"
            class="mr-1"
            @click="fetch()"
          >
            <span class="text-nowrap">
              <feather-icon
                class="cursor-pointer"
                icon="SearchIcon"
                size="16"
              />
              {{ $t('search') }}
            </span>
          </b-button>

          <!-- ANCHOR Button Clear Search Filters -->
          <b-button
            variant="danger"
            @click="fetch(true)"
          >
            <span class="text-nowrap">
              <feather-icon
                class="cursor-pointer"
                icon="XOctagonIcon"
                size="16"
              />
              {{ $t('clear') }}
            </span>
          </b-button>
        </div>
      </b-card-header>

      <b-card-body class="py-0">
        <b-container fluid>
          <b-row>
            <b-col md="4">
              <b-form-group
                label-class="h5 py-0"
                :label="$t('sepay.columns.accountHolderName')"
              >
                <b-form-input
                  v-model.trim="filter.q"
                  :placeholder="$t('sepay.search.bankAccount')"
                  debounce="500"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label-class="h5 py-0"
                :label="$t('sepay.columns.fullName')"
              >
                <v-select
                  v-model="filter.companyId"
                  :placeholder="$t('sepay.columns.fullName')"
                  :options="companies"
                  :clearable="false"
                  :reduce="e => e.id"
                  label="fullName"
                  @search="searchCompany"
                >
                  <template #option="data">
                    <span>
                      {{ data.id }} - {{ data.fullName }} ({{ data.shortName }})
                    </span>
                  </template>

                  <template #selected-option="data">
                    <span>
                      {{ data.id }} - {{ data.fullName }} ({{ data.shortName }})
                    </span>
                  </template>

                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>

    <!-- table -->
    <b-card no-body>
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <b-table
          ref="refDataTable"
          bordered
          no-border-collapse
          sticky-header
          responsive
          show-empty
          primary-key="id"
          style="max-height: 70vh"
          class="position-relative"
          table-class="table-resell-ticket"
          :items="items"
          :fields="tableColumns"
          :empty-text="$t('noRecordFund')"
          :sort-by.sync="filter.sortBy"
          :sort-desc.sync="filter.isSortDirDesc"
        >
          <!-- ANCHOR Header -->
          <template
            v-for="column in tableColumns"
            v-slot:[`head(${column.key})`]="data"
          >
            <div
              :key="column.label"
              class="text-dark text-nowrap text-center"
            >
              <template>
                {{ $t(`sepay.columns.${data.label}`) }}
              </template>
            </div>
          </template>

          <!-- ANCHOR Value -->
          <template
            v-for="column in tableColumns"
            v-slot:[`cell(${column.key})`]="{ item }"
          >
            <div
              :key="column.key"
              class="text-nowrap"
              :class="{
                'text-right': typeof item[column.key] === 'number',
              }"
            >
              {{ item[column.key] }}
            </div>
          </template>

          <!-- ANCHOR stt. -->
          <template #cell(stt)="data">
            <span class="text-right">
              {{ data.index + 1 }}</span>
          </template>

          <!-- ANCHOR lastTransaction. -->
          <template #cell(lastTransaction)="{ item }">
            <span class="text-right">
              {{ convertISODateTime(item.lastTransaction, 0).dateTime }}
            </span>
          </template>
          <!-- ANCHOR accumulated. -->
          <template #cell(accumulated)="{ item }">
            <span class="text-right">
              {{ formatVnCurrency(item.accumulated) }}
            </span>
          </template>

          <!-- ANCHOR bankApiConnected. -->
          <template #cell(bankApiConnected)="{ item }">
            <span class="text-right">
              {{ $t(item.bankApiConnected == 1 ? 'sepay.connected' : 'sepay.disconnected') }}
            </span>
          </template>

          <!-- ANCHOR bankId. -->
          <template #cell(bankId)="{ item }">
            <span class="d-flex gap-1 align-items-center">
              <BImg
                :src="resoleBank(item.bankId).logo_path"
                width="30"
              />
              <div>{{ resoleBank(item.bankId).short_name }} </div>
            </span>
          </template>

        </b-table>
      </b-overlay>
      <!-- paging -->
      <b-container
        fluid
        class="bg-white py-1"
      >
        <b-row>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-center"
          >
            <span class="text-muted">
              {{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }}
              {{ dataMeta.of }} {{ $t('paginationText.items') }}
            </span>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="filter.page"
              :total-rows="total"
              :per-page="filter.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="filter.perPage"
              :options="sizePerPageLgOptions"
              :clearable="false"
              class="per-page-selector "
            >
              <template #option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </b-col>
        </b-row>
      </b-container>

    </b-card>

    <!-- embed -->
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BContainer,
  BFormGroup,
  BFormInput,
  BImg,
  BOverlay,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { debounce } from 'lodash'

import {
  sizePerPageLgOptions,
} from '@/constants/selectOptions'

import {
  convertISODateTime,
  formatVnCurrency,
} from '@core/utils/filter'

import useSepayHandle from '@sepay/useSepayHandle'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BCard,
    BCardHeader,
    BCardBody,
    BTable,
    BPagination,
    BContainer,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BImg,
    vSelect,
  },
  setup() {
    //
    const {
      loading,
      bankAccounts: items,
      filterBankAccount: filter,
      totalBankAccount: total,
      fetchBankAccounts: fetch,
      companies,
      filterCompany,
      fetchCompanies,
    } = useSepayHandle()

    onMounted(() => {
      fetch()
      fetchCompanies()
    })

    const refDataTable = ref(null)
    const tableColumns = ref([
      { key: 'stt', label: 'stt' },
      { key: 'bankId', label: 'bankId' },
      { key: 'accountHolderName', label: 'accountHolderName' },
      { key: 'accountNumber', label: 'accountNumber' },
      { key: 'accumulated', label: 'accumulated' },
      { key: 'label', label: 'label' },
      { key: 'bankApiConnected', label: 'bankApiConnected' },
      { key: 'lastTransaction', label: 'lastTransaction' },
    ])

    const dataMeta = computed(() => {
      const { page, perPage } = filter.value

      const localItemsCount = refDataTable.value
        ? refDataTable.value?.localItems?.length
        : 0
      return {
        from: perPage * (page - 1) + (localItemsCount ? 1 : 0) || 0,
        to: perPage * (page - 1) + localItemsCount,
        of: total.value,
      }
    })
    const searchCompany = debounce(search => {
      filterCompany.value.q = search
      fetchCompanies()
    }, 300)

    function resoleBank(bankId) {
      const banks = [
        {
          id: '8',
          brand_name: 'MBBank',
          full_name: 'Ngân hàng TMCP Quân Đội',
          short_name: 'MBBank',
          code: 'MB',
          logo_path: 'https://my.sepay.vn/assets/images/banklogo/mbbank.png',
        },
        {
          id: '18',
          brand_name: 'OCB',
          full_name: 'Ngân hàng TMCP Phương Đông',
          short_name: 'OCB',
          code: 'OCB',
          logo_path: 'https://my.sepay.vn/assets/images/banklogo/ocb.png',
        },
      ]
      const bank = banks.find(e => e.id === bankId)
      return bank
    }
    return {
      loading,
      filter,
      items,
      total,
      tableColumns,
      refDataTable,
      dataMeta,
      fetch,
      companies,
      searchCompany,
      resoleBank,
      // import
      sizePerPageLgOptions,
      convertISODateTime,
      formatVnCurrency,
    }
  },
}
</script>
